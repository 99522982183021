Array.from(document.getElementsByClassName('is-drilldown-submenu-parent')).forEach(firstEl => {
  Array.from(firstEl.getElementsByClassName('nested')).forEach(nestedEl => {
    if (nestedEl.getElementsByClassName('nested').length) {
      Array.from(nestedEl.getElementsByClassName('nested')).forEach(thirdLevel => {
        thirdLevel.parentElement.classList.add('is-drilldown-submenu-parent');
        let backLi = document.createElement('li');
        backLi.innerHTML = '<button>Terug</button>';
        backLi.classList.add('js-drilldown-back');
        thirdLevel.insertBefore(backLi, thirdLevel.children[0]);
      });
    }
  });
});

('nav>ul').dropDown(el => {
  const dropDown = el.getElementsByClassName('navigation-group-items');
  if (dropDown.length > 0) {
    dropDown[0].style.display = 'flex';
  }
}, el => {
  const dropDown = el.getElementsByClassName('navigation-group-items');
  if (dropDown.length > 0) {
    dropDown[0].style.display = 'none';
  }
});

import SimpleLightbox from 'simplelightbox';

window.addEventListener('load', () => {
  let gallery = new SimpleLightbox('.lightbox__container a', {
    maxZoom: 5,
    loop: false,
    overlayOpacity: 0.45,
    navText: ['', ''],
    closeText: '',
    showCounter: false,
  });

  gallery.on('changed.simplelightbox', function (e) {
    const mediaGallery = document.getElementById('mediaGallery');
    const blazeSlider = mediaGallery['blazeSlider'];

    const thumbnailGallery = document.getElementById('thumbnailGallery');
    const blazeSliderThumbnail = thumbnailGallery['blazeSlider'];

    const index = e.target.getAttribute('data-index');

    blazeSlider.slideTo(index);
    blazeSliderThumbnail.slideTo(index);
  });
});

window.addEventListener('load', () => {
  const descriptionElement: HTMLElement = document.querySelector('.category__description');
  if (!descriptionElement) {
    return;
  }

  const textElement: HTMLElement = descriptionElement.querySelector('.category__text');
  const actionsElement: HTMLElement = descriptionElement.querySelector('._actions');
  const collapsedHeight = 96;

  const contentHeight = textElement.scrollHeight;

  if (contentHeight <= collapsedHeight) {
    actionsElement.remove();
    return;
  }

  const readMoreButton = document.createElement('button');
  readMoreButton.classList.add('_button', '--tertiary');
  readMoreButton.innerText = 'Lees meer';
  actionsElement.appendChild(readMoreButton);

  const expandedHeight = `${contentHeight}px`;

  textElement.style.maxHeight = '6rem';
  textElement.style.overflow = 'hidden';

  let isCollapsed = true;

  readMoreButton.addEventListener('click', () => {
    isCollapsed = !isCollapsed;
    textElement.style.maxHeight = isCollapsed ? '6rem' : expandedHeight;
    readMoreButton.innerText = isCollapsed ? 'Lees meer' : 'Lees minder';

    textElement.classList.toggle('_active', isCollapsed);
  });
});
