const initialState = () => {
  return {
    apiResponse: {},
    apiResponsePending: false,
    blockId: 0,
    defaultAttributes: {},
    urlFiltersFeatureEnabled: false,
  }
};

const state = initialState();

const mutations = {
  SET_API_RESPONSE_PENDING(state, status) {
    state.apiResponsePending = status;
  },
  SET_API_RESPONSE(state, payload) {
    state.apiResponse = payload;
  },
  SET_BLOCK_ID(state, payload) {
    state.blockId = payload;
  },
  SET_DEFAULT_ATTRIBUTES(state, payload) {
    state.defaultAttributes = payload;
  },
  SET_URL_FILTERS_FEATURE_ENABLED(state, payload) {
    state.urlFiltersFeatureEnabled = payload;
  }
};

const actions = {
  setApiResponse({commit}, response) {
    commit('SET_API_RESPONSE', response);
  },
  setBlockId({commit}, payload) {
    commit('SET_BLOCK_ID', payload);
  },
  setDefaultAttributes({commit}, payload) {
    commit('SET_DEFAULT_ATTRIBUTES', payload);
  },
  updatePage({dispatch, state}, payload) {
    dispatch('fetchCatalog', {
      pageQueries: {page: payload.page},
      blockId: state.blockId
    });
  },
  updatePriceFilter({dispatch}, payload) {
    dispatch('fetchCatalog', {
      pageQueries: {minPrice: payload.minPrice, maxPrice: payload.maxPrice, page: 1}
    });
  },
  updatePageSize({dispatch, state, getters}, payload) {
    let pageQueries = {page_size: payload};

    if (getters.pageSize !== payload) {
      pageQueries['page'] = 1;
    }

    dispatch('fetchCatalog', {
      pageQueries: pageQueries,
      blockId: state.blockId
    });
  },
  updatePageSort({dispatch, state}, payload) {
    let sortString = '';

    if (typeof payload === 'object') {
      Object.entries(payload).forEach(([key, value]) => {
        sortString = `${key} ${value}`
      });
    }

    dispatch('fetchCatalog', {
      pageQueries: {sort: sortString, page: 1},
      blockId: state.blockId
    });
  },
  filterAction({dispatch, getters}, payload) {
    dispatch('fetchCatalog', {
      filters: getters.filterList.map(filter => {
        if (filter['code'] === payload.filterCode) {
          filter['options'].forEach(option => {
            if (option['label'] === payload.optionLabel) {
              switch (payload.actionType) {
                case 'toggle':
                  option['active'] = !option['active'];
                  break;
                case 'activate':
                  option['active'] = true;
                  break;
                case 'deactivate':
                  option['active'] = false;
                  break;
              }
            }
          });
        }
        return filter;
      }),
      pageQueries: {
        page: 1
      },
      blockId: state.blockId
    });
  },
  toggleFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'toggle',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
      blockId: payload.blockId
    });
  },
  fetchCatalog({state, commit}, payload) {
    commit('SET_API_RESPONSE_PENDING', true);

    const filters = payload.filters || (state.apiResponse.filters || []);

    let params = {};
    let queryParams = payload.pageQueries || {};

    if ('undefined' === typeof queryParams['minPrice'] && getters.priceFilters(state)[0]?.MinimumValue > 0) {
      queryParams['minPrice'] = getters.priceFilters(state)[0].MinimumValue;
    }

    if ('undefined' === typeof queryParams['maxPrice'] && getters.priceFilters(state)[0]?.MaximumValue > 0) {
      queryParams['maxPrice'] = getters.priceFilters(state)[0].MaximumValue;
    }

    queryParams['page_size'] = queryParams['page_size'] || getters.pageSize(state);

    if ('undefined' === typeof queryParams['sort'] && null !== getters.currentSort(state)) {
      let sortString = '';
      Object.entries(getters.currentSort(state) || {}).forEach(([key, value]) => {
        sortString = `${key} ${value}`
      });

      queryParams['sort'] = sortString;
    }

    if (filters.length) {
      let activeFilters = filters.filter(item => {
        if (item.type !== 'attribute') {
          return false;
        }

        let activeOptions = item.options.filter(option => {
          return option.active;
        });

        return activeOptions.length;
      });

      let filterSegments = {};
      activeFilters.forEach(filter => {
        filter.options.forEach(option => {
          if (option.active) {
            if (!filterSegments.hasOwnProperty(filter['facet_code'])) {
              filterSegments[filter['facet_code']] = [];
            }

            filterSegments[filter['facet_code']].push(
                `${option.label}`
            );
          }
        });
      });
      params = Object.assign(filterSegments);
    }

    const apiUrl = `/api/page-builder-landing-pages/catalog/${state.blockId}`;

    this.$solarClient.get(
        apiUrl, {
          params: Object.assign(params, queryParams)
        }).then(({data}) => {
      commit('SET_API_RESPONSE', data);

      if ( ! state.urlFiltersFeatureEnabled) {
        return;
      }

      let defaultAttributeFilters = [];
      Object.keys(state.defaultAttributes).forEach(function (key, index) {
        for (const filterValue in state.defaultAttributes[key]) {
          defaultAttributeFilters.push(`${key}-${state.defaultAttributes[key][filterValue]}`);
        }
      });

      const urlFilters = data.filters.filter(filter => filter.is_active).flatMap(filter =>
          filter.options.filter(option => option.active).map(option => {
            const prefix = filter.has_duplicate_values ? `${filter.url_key}-` : '';
            return `${prefix}${option.label.replace(/\s/g, '-')}`;
          }).filter(filterKeyValue => !defaultAttributeFilters.includes(filterKeyValue))
      ).filter(filterArray => filterArray.length > 0);

      let urlSegments = window.location.pathname.split('/').filter(segment => segment);
      const urlHasFilterSegment = urlSegments.filter(segment => segment[0] === '_').length > 0;
      if (urlHasFilterSegment && ! urlFilters.length) {
        urlSegments = urlSegments.filter(segment => segment[0] !== '_');
      }

      if (urlFilters.length) {
        const encodedActiveFilterValues = urlFilters.map(v => encodeURIComponent(v));

        if (urlHasFilterSegment) {
          urlSegments = urlSegments.map(segment => {
            return segment[0] === '_' && urlSegments.length ? `_${encodedActiveFilterValues.join('_')}` : segment;
          });
        } else {
          urlSegments.push(`_${encodedActiveFilterValues.join('_')}`);
        }
      }

      let queryString = '';
      if (Object.keys(queryParams).length) {
        Object.entries(queryParams).forEach(([key, value]) => {
          queryString += `&${key}=${value}`;
        });

        queryString = `?${queryString.substr(1)}`;
      }

      window.history.pushState({
            catalogData: data
          },
          // @ts-ignore
          window.document.querySelector('title').value,
          window.location.origin + '/' + urlSegments.join('/') + queryString
      );
    }).finally(() => {
      commit('SET_API_RESPONSE_PENDING', false);
    });
  }
};

const getters = {
  productList: state => {
    return state.apiResponse.items || [];
  },
  filterList: state => {
    return state.apiResponse.filters || [];
  },
  totalItemCount: state => {
    return state.apiResponse.total_items || 0;
  },
  currentPage: state => {
    return state.apiResponse['current_page'] || 1;
  },
  currentSort: state => {
    return state.apiResponse['sorting'] || null;
  },
  totalPages: state => {
    return state.apiResponse['last_page'] || 1;
  },
  pageSize: state => {
    return state.apiResponse['page_size'] || 24;
  },
  activeFilters: state => {
    return state.apiResponse.filters || [];
  },
  priceFilters: state => {
    return (state.apiResponse.filters || []).filter(f => f['type'] === 'price') || [];
  },
  blockId: state => {
    return state.blockId;
  },
  urlFiltersFeatureEnabled: state => {
    return state.urlFiltersFeatureEnabled;
  }
};

const CatalogPageBuilder = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default CatalogPageBuilder;
