"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var initialState = function () {
  return {
    availableOptions: [],
    selectedOptions: []
  };
};
var state = initialState();
var mutations = {
  SET_AVAILABLE_OPTIONS: function (state, payload) {
    state.availableOptions = payload;
  },
  SET_SELECTED_OPTIONS: function (state, payload) {
    state.selectedOptions = state.selectedOptions.filter(function (o) { return o.product_id !== payload.product_id; });
    state.selectedOptions.push(payload);
  }
};
var actions = {
  getAvailableOptions: function (_a, payload) {
    var state = _a.state, commit = _a.commit;
    commit('SET_AVAILABLE_OPTIONS', payload);
  },
  updateSelectedOption: function (_a, payload) {
    var state = _a.state, commit = _a.commit;
    commit('SET_SELECTED_OPTIONS', payload);
  }
};
var getters = {
  totalPrices: function (state, getters, rootState) {
    return {
      priceInclTax: rootState.CatalogProduct.productData.price_incl_tax,
      priceExclTax: rootState.CatalogProduct.productData.price_excl_tax,
      totalPriceInclTax: rootState.CatalogProduct.productData.final_price_incl_tax,
      totalPriceExclTax: rootState.CatalogProduct.productData.final_price_excl_tax
    };
  },
  isSaleable: function (state, getters, rootState) {
    return rootState.CatalogProduct.productData.bundle_is_salable;
  }
};
var CatalogBundleProduct = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
exports.default = CatalogBundleProduct;
