const initialState = () => {
    return {}
};

const state = initialState();

const mutations = {};

const actions = {
    async fireGaEvent({}, payload: object) {
        const {data} = await this.$solarClient.post(
            '/api/ga/measure/event/' + payload['event'],
            payload
        )

        return data;
    },
};

const MeasurementProtocol = {
    namespaced: true,
    state,
    mutations,
    actions,
};

export default MeasurementProtocol;
