const initialState = () => {
  return {
    products: [],
    isLoading: false,
    wishlistItemCount: 0
  }
};

const state = initialState();

const mutations = {
  SET_WISHLIST_PRODUCTS(state, payload) {
    state.products = payload;
  },
  SET_IS_LOADING(state, status) {
    state.isLoading = status;
  },
  SET_WISHLIST_COUNT(state, count) {
    state.wishlistItemCount = count;
  },
  UPDATE_WISHLIST_COUNT(state, update) {
    update ? state.wishlistItemCount++ : state.wishlistItemCount--;
  }
};

const actions = {
  setWishlistCount({commit}, payload) {
    commit('SET_WISHLIST_COUNT', payload);
  },
  setWishlistProducts({commit}, payload) {
    commit('SET_WISHLIST_PRODUCTS', payload);
  },
  updateWishlistCount({commit}, payload) {
    commit('UPDATE_WISHLIST_COUNT', payload);
  },
  async toggleWishlistProduct({state, commit}, productId) {
    commit('SET_IS_LOADING', true);
    try {
      const {data} = await this.$solarClient.put(`/api/customer/wishlist/item/toggle`, {
        productId: productId
      });
    } catch (e) {
      console.log(e);
    } finally {
      commit('SET_WISHLIST_PRODUCTS', state.products.filter(p => p.product_id !== productId));
      commit('SET_IS_LOADING', false);
    }
  }
};

const getters = {
  wishlistProducts: state => {
    return state.products;
  },
  wishlistCount: state => {
    return state.wishlistItemCount;
  }
}

const CustomerWishlist = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default CustomerWishlist;
